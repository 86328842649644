import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import { sendPasswordResetEmail } from '../../../api/auth';

export default function ForgotPassword() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.querySelector('body').classList.add('loaded');
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    const email = e.target.email.value;
    const result = await sendPasswordResetEmail(email);
    if (result && result.status === 200) {
      setMessage('Password reset email sent successfully');
      setShow(true);
    } else {
      setMessage('Failed to send password reset email');
      setShow(true);
    }
  }

  return (
    <section className='body-sign'>
      <div className='center-sign'>
        {show && message && (
          <Alert variant='danger'>{message}</Alert>
        )}
        <Card className='card-sign'>
          <Card.Body className='login-card'>
            <div className='mb-3 text-center'>
              {/* TODO REPLACE THIS LINK */}
               <img style={{ width: '15rem' }} src={process.env.PUBLIC_URL + "/assets/images/logo/qr-code-svgrepo-com-large.png"} alt="qr_logo" />
            </div>
            <h2 className='sign-title mb-4'>Resetare parolă</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group className='mb-4'>
                <Form.Control name='email' type='email' placeholder='exemplu@gmail.com' required autoFocus />
              </Form.Group>
              <Button type='submit' className='btn-login'>
                Trimite email
              </Button>
            </Form>
          </Card.Body>
        </Card>

        <p className='text-center text-muted my-3'>
          &copy; 2024 QR Casa Verde. All Rights Reserved.
        </p>
      </div>
    </section>
  );
}
