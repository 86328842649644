import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Outlet, useNavigate } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import 'react-image-lightbox/style.css';

import Header from './common/header';
import Sidebar from './common/sidebar';

import { isIEBrowser, isEdgeBrowser, isFirefoxBrowser } from '../utils';
import { check } from '../api/auth';

export default function Layout({ user }) {
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    async function checkJWT() {
      const result = await check();
      if ((!result || result.status !== 200) || localStorage.getItem('isLoggedIn') === 'false') {
        setShow(true);
        navigate(`${process.env.PUBLIC_URL}/`);
      }
    }
    checkJWT();
    if (show) {
      document.querySelector('body').classList.add('loaded');
      window.addEventListener('scroll', scrollHander, true);

      return () => {
        window.removeEventListener('scroll', scrollHander);
        setShow([{}]);
      };
    }
  }, [navigate, show]);

  function scrollHander() {
    if (window.pageYOffset > 400) {
      document.querySelector('.scroll-to-top') &&
        document.querySelector('.scroll-to-top').classList.add('visible');
    } else {
      document.querySelector('.scroll-to-top') &&
        document.querySelector('.scroll-to-top').classList.remove('visible');
    }
  }

  function toTop(e) {
    if (isIEBrowser() || isEdgeBrowser() || isFirefoxBrowser()) {
      let pos = window.pageYOffset;
      let timer = setInterval(() => {
        if (pos <= 0) clearInterval(timer);
        window.scrollBy(0, -40);
        pos -= 40;
      }, 1);
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    e.preventDefault();
  }

  return (
    <div className="d-flex vh-100 bg-light">
      <Sidebar user={user} />
      <div className="d-flex flex-column w-100">
        <Header user={user} />
        <div className="flex-grow-1 overflow-auto p-4 pt-0 bg-white h-100" style={{ position: 'relative' }}>
          {/* <div className="bg-white shadow-lg p-4 rounded" style={{ height: '100%' }}> */}
            <Outlet context={{ user }} />
          {/* </div> */}
        </div>
      </div>
      <a href="#top" className="scroll-to-top d-none d-lg-block" onClick={toTop} style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '1000' }}>
        <i className="fas fa-chevron-up"></i>
      </a>
      <ToastContainer
        className="ui-pnotify"
        closeButton={false}
        closeOnClick={false}
        draggable={false}
        position="top-right"
        hideProgressBar={true}
        autoClose={3000}
        containerId="default"
        enableMultiContainer={true}
      />
      <ToastContainer
        className="ui-pnotify stack-bottomleft"
        closeButton={false}
        closeOnClick={false}
        draggable={false}
        position="bottom-left"
        hideProgressBar={true}
        newestOnTop={true}
        autoClose={3000}
        containerId="bottom-left"
        enableMultiContainer={true}
      />
      <ToastContainer
        className="ui-pnotify stack-bottomright"
        closeButton={false}
        closeOnClick={false}
        draggable={false}
        position="bottom-right"
        hideProgressBar={true}
        newestOnTop={true}
        autoClose={3000}
        containerId="bottom-right"
        enableMultiContainer={true}
      />
      <ToastContainer
        className="ui-pnotify stack-bar-top"
        closeButton={false}
        closeOnClick={false}
        draggable={false}
        position="top-left"
        hideProgressBar={true}
        autoClose={3000}
        containerId="top-bar"
        enableMultiContainer={true}
      />
      <ToastContainer
        className="ui-pnotify stack-bar-bottom"
        closeButton={false}
        closeOnClick={false}
        draggable={false}
        position="bottom-left"
        hideProgressBar={true}
        newestOnTop={true}
        autoClose={3000}
        containerId="bottom-bar"
        enableMultiContainer={true}
      />
    </div>
  );
}
