import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Dropdown } from 'react-bootstrap';
import { getLoggedUser } from '../../api/auth';

export default function Header({ user }) {
  const [userRefresh, setUserRefresh] = useState();
  const [showUserBox, toggleUserBox] = useState(false);
  const RefreshIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" fill="none">
      <path d="M12 21C7.02944 21 3 16.9706 3 12C3 9.69494 3.86656 7.59227 5.29168 6L8 3M12 3C16.9706 3 21 7.02944 21 12C21 14.3051 20.1334 16.4077 18.7083 18L16 21M3 3H8M8 3V8M21 21H16M16 21V16" stroke="#000000" stroke-linejoin="round" />
    </svg>
  );

  useEffect(() => {
    document.querySelector('body').addEventListener('click', onBodyClick);
    return () => {
      document.querySelector('body').removeEventListener('click', onBodyClick);
    };
  }, []);

  const refreshUserCredits = async () => {
    const result = await getLoggedUser();
    if (result.status === 200) {
      setUserRefresh(result.data);
    }
  };

  function onToggleUserBox(e) {
    e.preventDefault();
    toggleUserBox(!showUserBox);
  }

  function onBodyClick(e) {
    if (!e.target.closest('.userbox')) {
      toggleUserBox(false);
    }
  }

  return (
    <header className='d-flex flex-wrap align-items-center bg-white p-2'>
      <div className='d-flex align-items-center flex-grow-1 justify-content-end flex-wrap px-5 py-3'>
        <Row className='align-items-center flex-grow-1'>
          <Col as={Row} className='mt-2 mt-md-0 flex-row align-items-center flex-grow-1 w-100 flex-nowrap mt-3 justify-content-end' style={{ gap: '1rem' }}>
            <span className='text-nowrap'>{userRefresh ? userRefresh.credits : user.credits} fact</span>
            {/* <Button className='primary-btn text-nowrap'>Incarca acum</Button> */}
            <Button onClick={refreshUserCredits} className="primary-btn">
              <RefreshIcon />
            </Button>
          </Col>
          <Col xs="auto" className='d-flex justify-content-end pr-0 pl-5'>
            <Dropdown show={showUserBox} onToggle={() => toggleUserBox(!showUserBox)}>
              <Dropdown.Toggle as="a" href="#" className='d-flex align-items-center'>
                <div className='d-flex flex-column flex-wrap pr-2'>
                  <span className='text-dark'>{user.firstName}</span>
                  <span className='text-muted'>{user.roleName}</span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`${process.env.PUBLIC_URL}/dashboard/account-settings`}>
                  <i className='fa fa-cog'></i> Setări cont
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={`${process.env.PUBLIC_URL}/logout`}>
                  <i className='bx bx-log-out mb-2'></i> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </header>
  );
}
