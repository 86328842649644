import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form'
import { Link } from "react-router-dom";
import UserContext from "../../../utils/context/userContext";
import { useContext } from "react";
import { updateSettingsAccount } from "../../../api/user";
import { toast } from "react-toastify";
import PNotify from "../../../features/elements/p-notify";

const AccountSettings = () => {
    const { handleSubmit, control, formState: { errors }, reset, watch } = useForm();
    const checkPassword = watch('checkPassword', '');

    const validatePasswordMatch = (value) => {
        if (value !== checkPassword) {
            return "Parolele nu se potrivesc";
        }
        return true;
    };

    const onSubmit = async (formData) => {
        const result = await updateSettingsAccount(formData, user.id);
        if (result && !result.data.error) {
            reset();
            return toast(
                <PNotify
                    title='Success'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-success',
                }
            );
        } else {
            return toast(
                <PNotify
                    title='Error'
                    icon='fas fa-times'
                    text={<p className='text-white'>{'A apărut o eroare!'}</p>}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }

    }

    const user = useContext(UserContext);

    return (
        <>
            <div className="p-4 h-fit">
                <div className="bg-white shadow-lg p-4 rounded" style={{ height: '100%' }}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="p-4">
                            <Col xs={6}>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{ required: 'Acest câmp este obligatoriu' }}
                                    defaultValue={user.email}
                                    render={({ field }) => (
                                        <Form.Group controlId="email">
                                            <Form.Label style={{ fontSize: '1rem' }}><i className="fa fa-envelope fa-lg mr-2"></i>Email</Form.Label>
                                            <Form.Control type="email" name="email" style={{ borderRadius: '10px' }} placeholder="exemplu@email.com" value="" {...field} isInvalid={!!errors.email} />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email && errors.email.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col xs={6}>
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{
                                        required: 'Acest câmp este obligatoriu',
                                        minLength: {
                                            value: 8,
                                            message: 'Parola trebuie să aibă cel puțin 8 caractere'
                                        },
                                        validate: validatePasswordMatch
                                    }}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <Form.Group controlId="password">
                                            <Form.Label style={{ fontSize: '1rem' }}><i className="fa fa-envelope fa-lg mr-2"></i>Parolă</Form.Label>
                                            <Form.Control type="password" name="password" placeholder="Parolă" style={{ borderRadius: '10px' }} value="" {...field} isInvalid={!!errors.password} />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password && errors.password.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                />
                                <Controller
                                    name="checkPassword"
                                    control={control}
                                    rules={{ required: 'Acest câmp este obligatoriu' }}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <>
                                            <Form.Group controlId="checkPassword" className="pt-3">
                                                <Form.Control type="password" name="checkPassword" style={{ borderRadius: '10px' }} placeholder="Confirmă parola" {...field} isInvalid={!!errors.checkPassword} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.checkPassword && errors.checkPassword.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col xs={6}>
                                <Row>
                                    <Col className="text-center mb-2">
                                        <Button type='submit' className="primary-btn pl-3 pr-3">
                                            <span style={{ fontSize: '1rem', fontWeight: '500' }}>Trimite</span>
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col className="text-center">
                                        <Link to="/forgot-password" className="text-muted">Am uitat parola</Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default AccountSettings;