import Modal from "react-modal";
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const modalStyles = {
    overlay: {
        background: 'rgba(11, 11, 11, .8)',
        zIndex: 99999
    },
    content: {
        left: '50%',
        top: '50%',
        bottom: 'auto',
        right: 'auto',
        outline: 'none',
        maxWidth: '100%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px',
    }
};

Modal.setAppElement('#app');

export default function FinishInvoiceModal({ isOpen, isError, onClose }) {
    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyles}>
            <div className="d-flex justify-content-end" style={{ gap: '1rem' }}>
                <i className="fa fa-times fa-2x" style={{ cursor: 'pointer' }} onClick={() => onClose('close')}></i>
            </div>
            <Card>
                {!isError && (
                    <Card.Body style={{ padding: '3rem', boxShadow: 'none' }}>
                        <Row className="d-flex flex-column justify-content-center align-items-center text-center" style={{ gap: '1.5rem' }}>
                            <Col>
                                <img src="https://s3-alpha-sig.figma.com/img/e21f/c13a/94a64a6721ff7194926855c287eae200?Expires=1721606400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=o46nqL60~sUbZyyTAshN2DC-TgIcJv0NUe1bRRiJ5Ku13Y4OdlW5bbEyXImNAiwsOWdCAep8ebY5RtIBUlGtTHEB1NEUGdG1zyKETI3Pg0IO4bOwtHN6LgtT-7UpBVh2Gwwj1tB7uZ3aQUUAOqAYVKltFsnzNn7-dsIHPdRR94twSGYCO6Fnk2ut7M8a7c09r8iSmDQTzNiivdDAi~PhBh~lMLc9FfGvb066MehEyZlg9tVwA9qYXiDvXxT5tz2boymmvDOhchEpRKKQOUf7xqznVP4egU1sfJJeDVIHsotsLBaD2F2JGAZHFA1-jLCHK-3N41FZjhIA4XoHPtzNwA__" alt="" />
                            </Col>
                            <Col style={{ marginTop: '-3rem' }}>
                                <h5 style={{ fontWeight: '700' }}>QR codul a fost generat și atașat cu succes facturii!</h5>
                            </Col>
                            <Col className="d-flex flex-row justify-content-center align-items-center" style={{ gap: '4rem' }}>
                                <Link to="/dashboard/invoices" className="text-muted">Vezi factura</Link>
                                <Link style={{ color: "black", fontWeight: '500' }} onClick={() => onClose(true)}>Încarcă altă factură</Link>
                            </Col>
                        </Row>
                    </Card.Body>
                ) || (
                        <Card.Body style={{ padding: '3rem', border: '0px', boxShadow: 'none' }}>
                            <Row className="d-flex flex-column justify-content-center align-items-center text-center" style={{ gap: '1.5rem' }}>
                                <Col>
                                    <img width={'40%'} src="https://s3-alpha-sig.figma.com/img/578b/7be1/5dfea4f68ae6b4688fffd32fb629c32d?Expires=1721606400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=KCEUjvBPNqP8djOaDumKPi4D4wOgDPvYkOJaui28yAOQ6zJnWCgaWLcrMoBHlENGYpOasez7YxsN41K-QJfcu41d8WOuXYA-9qV92fh4Ef-qpZTx6cZXjoVelw7r~nioQYwZ-2ymR~Ja8iT4D7uCM0DLHFVbtUEbto0~WrM1LQc7QJKQW-qguT~2iJTuriz7WnLRy-UqmPr9CSvrarvoMy7asZ-LHqxf13L7EhH5lvaFk1caYXoXCFKmMs~8B2Ov49Lx~KAmsSmnnJqvAnOwCn~dpJyw9aliSluQu-BPVY1M-Q2ZYugoJY7Lut4VASMjwE2MX4g-2VweHaP~OajL8g__" alt="" />
                                </Col>
                                <Col style={{ marginTop: '' }}>
                                    <h5 style={{ fontWeight: '700' }}>A apărut o eroare la generarea codului QR. <br /> Vă rugăm să încercați din nou mai târziu.</h5>
                                </Col>
                                <Col className="d-flex flex-row justify-content-center align-items-center" style={{ gap: '4rem' }}>
                                    <Link onClick={() => onClose(true)} className="text-muted">Anulează</Link>
                                    <Link style={{ color: "black", fontWeight: '500' }} onClick={() => onClose('resend')}>Reîncearcă</Link>
                                </Col>
                            </Row>
                        </Card.Body>
                    )}
            </Card>
        </Modal>
    )
}