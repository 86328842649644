import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { check, login } from '../../../api/auth';
import { withDismissible } from '../../hoc/index';

export default function SignIn() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    async function checkJWT() {
      const result = await check();
      if (result && result.status === 200) {
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      } else {
        localStorage.setItem('isLoggedIn', 'false');
      }

    }
    if (localStorage.getItem('isLoggedIn') === 'true') {
      checkJWT();
    }
    document.querySelector('body').classList.add('loaded');
  }, [navigate]);

  async function handleSubmit(event) {
    event.persist();
    setShow(false);
    setMessage('');
    event.preventDefault();

    const data = {
      email: event.target.email.value,
      password: event.target.password.value,
      remember: event.target.remember.checked,
      // token: recaptcha,
    };
    const result = await login(data);
    if (result.status === 200 && result.data.error !== 1) {
      setShow(false);
      setMessage('');
      const result = await check(data);
      if (result.status === 200) {
        localStorage.setItem('isLoggedIn', true);
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
        navigate(0);
      } else {
        setMessage(result.data.message);
        setShow(true);
      }
    } else {
      setMessage(result.data.message ?? result.data.error + ": Please try again!");
      setShow(true);
    }
  }

  return (
    <section className='body-sign'>
      <div className='center-sign'>
        {show && message && (
          <Alert variant='danger'>{message}</Alert>
        )}
        <Card className='card-sign'>
          <Card.Body className='login-card'>
            <div className='mb-3 text-center'>
              {/* TODO REPLACE THIS LINK */}
              <img style={{ width: '15rem' }} src={process.env.PUBLIC_URL + "/assets/images/logo/qr-code-svgrepo-com-large.png"} alt="qr_logo" />
            </div>
            <h2 className='sign-title mb-4'>Autentificare</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group className='mb-1'>
                <Form.Control name='email' type='email' placeholder='exemplu@gmail.com' required autoFocus />
              </Form.Group>
              <Form.Group>
                <Form.Control name='password' type='password' required />
              </Form.Group>
              <Row className='rem-password'>
                <Col sm={8}>
                  <Form.Check
                    name='remember'
                    className='checkbox-default text-muted'
                    label='Ține-ma minte!'
                  />
                </Col>
                <Col sm={4} style={{ textAlign: 'end' }}>
                  <Link className='text-muted' to='/forgot-password'>Ai uitat parola?</Link>
                </Col>
              </Row>
              <Button type='submit' className='btn-login'>
                Autentificare
              </Button>
            </Form>
            <Row className='mt-5'>
              <Col sm={12}>
                <p className='text-center text-muted'>Nu ai cont? <Link style={{ color: "#000000" }} to={'/register'}>Înregistrează-te</Link></p>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <p className='text-center text-muted my-3'>
          &copy; 2024 QR Casa Verde. All Rights Reserved.
        </p>
      </div>
    </section>
  );
}
