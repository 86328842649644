import { roles } from "./role"
const menuData = [
    {
        "name": "Generează QR",
        "icon": "fa fa-qrcode",
        "url": "/dashboard",
        "role": [roles.user],
    },
    {
        "name": "Facturile mele",
        "icon": "fa fa-file-invoice",
        "url": "/dashboard/invoices"
    },
    {
        "name": "Users",
        "icon": "fa fa-qrcode",
        "url": "/dashboard/users",
        "role": [roles.admin],
    },
    {
        "name": "Facturi",
        "icon": "fa fa-qrcode",
        "url": "/dashboard/facturi",
        "role": [roles.admin],
    },
    {
        "name": "Credite",
        "icon": "fa fa-qrcode",
        "url": "/dashboard/credite",
        "role": [roles.admin],
    },
    {
        "name": "Logs",
        "icon": "fa fa-qrcode",
        "url": "/dashboard/logs",
        "role": [roles.admin],
    },
    // {
    //     "name": "Plăți",
    //     "icon": "bx bx-money",
    //     "url": "/dashboard/payments"
    // },
]

export default menuData;