import React, { useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import { Button } from 'react-bootstrap';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const PDFViewer = ({ fileUrl, handleConfirmation, handleRetry }) => {
    const [pdf, setPdf] = useState(null);
    const [pages, setPages] = useState([]);
    const [activePage, setActivePage] = useState(null);
    const canvasRefs = useRef([]);
    const [qrPosition, setQrPosition] = useState({ x: null, y: null });

    useEffect(() => {
        const loadPdfAndPages = async () => {
            try {
                const loadingTask = pdfjsLib.getDocument(fileUrl);
                const loadedPdf = await loadingTask.promise;
                setPdf(loadedPdf);

                const pdfPages = Array.from({ length: loadedPdf.numPages }, (_, i) => i + 1);
                setPages(pdfPages);
            } catch (error) {
                console.error('Error loading PDF:', error);
                // Handle error loading PDF
            }
        };

        if (fileUrl) {
            loadPdfAndPages();
        }
    }, [fileUrl]);

    useEffect(() => {
        const renderCanvas = async (pageIndex) => {
            if (!pdf || !canvasRefs.current[pageIndex].current) return;

            const canvas = canvasRefs.current[pageIndex].current;
            const context = canvas.getContext('2d');
            const page = await pdf.getPage(pageIndex + 1); // pageIndex is 0-based
            const viewport = page.getViewport({ scale: 1 });

            // Set canvas width and height
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            // Render PDF page onto canvas
            await page.render({
                canvasContext: context,
                viewport: viewport
            }).promise;

            // Draw QR code if position is set
            if (qrPosition.x !== null && qrPosition.y !== null && (activePage !== null && activePage === pageIndex)) {
                drawQRCode(context, qrPosition.x, qrPosition.y);
            }
        };

        if (pdf) {
            pages.forEach((_, index) => {
                renderCanvas(index);
            });
        }
    }, [pdf, pages, qrPosition]);

    const handleCanvasClick = (pageIndex, event) => {
        const canvas = canvasRefs.current[pageIndex].current;
        if (!canvas) return;

        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        // Calculate PDF coordinates
        const x = (event.clientX - rect.left) * scaleX;
        const y = (event.clientY - rect.top) * scaleY;

        // Set QR code position
        setActivePage(pageIndex);
        setQrPosition({ x, y });
    };

    const drawQRCode = (context, x, y) => {
        // Draw a mock QR code (for demonstration purposes)
        const qrSize = 200; // Adjust size as needed
        const borderSize = 2; // Border size
        const qrText = 'QR CODE HERE';

        // Calculate starting point for QR code
        const qrX = x;
        const qrY = y; // Draw down-right from click position

        // Draw border
        context.fillStyle = 'white'; // Background color
        context.fillRect(qrX, qrY, qrSize, qrSize);

        // Draw QR code
        context.fillStyle = 'black'; // QR code color
        context.font = '16px Arial';
        context.fillText(qrText, qrX + 10, qrY + 20); // Adjust text position as needed

        // Draw border
        context.strokeStyle = 'black'; // Border color
        context.lineWidth = borderSize;
        context.strokeRect(qrX, qrY, qrSize, qrSize);
    };


    useEffect(() => {
        // Create refs for each canvas
        if (pdf) {
            canvasRefs.current = Array(pdf.numPages).fill().map((_, i) => canvasRefs.current[i] || React.createRef());
        }
    }, [pdf]);

    return (
        pdf && (
            <>
                <div style={{ position: 'relative', zIndex: 10000, overflow: 'auto', maxHeight: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {pages.map((pageIndex) => (
                            <canvas
                                key={pageIndex}
                                ref={canvasRefs.current[pageIndex - 1]} // pageIndex - 1 to match 0-based index
                                onClick={(event) => handleCanvasClick(pageIndex - 1, event)} // pageIndex - 1 for correct index
                                style={{ border: '1px solid black' }} // Optional: Add styling for visualization
                            />
                        ))}
                    </div>
                </div>
                <div className="button-group mt-3 d-flex flex-column align-items-center">
                    <Button onClick={() => handleConfirmation({ qrPosition, activePage })} className="mb-2 primary-btn p-3">
                        <span style={{ fontSize: '1rem', fontWeight: '500' }}>Salvează coordonatele</span>
                    </Button>
                    <Button variant="ghost" onClick={handleRetry}>
                        <span style={{ fontSize: '1rem', fontWeight: '500' }}>Încarcă alt fișier</span>
                    </Button>
                </div>
            </>

        )
    );
};

export default PDFViewer;
