import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ForgotPassword from './components/pages/others/forgot-password';
import SignIn from './components/pages/others/sign-in';
import Layout from './components/layout';
import Dashboard from './components/pages/dashboard';
import Logout from './components/pages/others/logout';
import PageNotFound from './components/pages/others/page-not-found';
import UserContext from './utils/context/userContext';
import { getLoggedUser } from './api/auth';
import { LogProvider } from './utils/context/logContext';
import NewPassword from './components/pages/others/new-password';
import Registration from './components/pages/others/registration';
import Invoices from './components/pages/invoices/invoices';
import AccountSettings from './components/pages/account-settings/account-settings';
import Packages from './components/pages/packages/packages';
import GenerateQr from './components/pages/generate-qr/generate-qr';

function App() {
  const [user, setUser] = useState('');

  useEffect(() => {
    if (localStorage.getItem('isLoggedIn') === 'true') {
      async function getUser() {
        const result = await getLoggedUser();
        if (result.status === 200) {
          setUser(result.data);
        }
      }
      if (!user) {
        getUser();
      }
    }
    document.querySelector('body').classList.add('loaded');
  }, [user]);

  return (
    <React.StrictMode>
      <Router>
        <UserContext.Provider value={user}>
          <LogProvider>
            <Routes>
              <Route path='/'>
                <Route index element={<SignIn />} />
                <Route path='/login' element={<SignIn />} />
                <Route path='/logout' element={<Logout />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/new-password/:id' element={<NewPassword />} />
                <Route path='/register' element={<Registration />} />
                {user ?
                  <Route path='/dashboard' element={<Layout user={user} />}>
                    <Route index element={<Dashboard user={user} />} />
                    <Route path='generate-qr' element={<GenerateQr user={user} />} />
                    <Route path='account-settings' element={<AccountSettings />} />
                    <Route path='invoices' element={<Invoices user={user} />} />
                    <Route path='packages' element={<Packages />} />
                    <Route path='*' element={<PageNotFound />} />
                  </Route>
                  :
                  <Route path='/dashboard' element={<Layout user={user} />}>
                    <Route path='*' element={<PageNotFound />} />
                  </Route>
                }
                <Route path='/*' element={<PageNotFound />} />
              </Route>
            </Routes>
          </LogProvider>
        </UserContext.Provider>
      </Router>
    </React.StrictMode>
  );
}

ReactDOM.render(<App />, document.getElementById('app'));
