import UserContext from "../../../utils/context/userContext";
import { useContext, useState } from "react";
import CustomPackage from "./cards/custom-package";
import Package from "./cards/package";
import "./cards/style.css"; // Import a CSS file for custom styling (if needed)
import { Col, Row } from "react-bootstrap";

const Packages = () => {
    const user = useContext(UserContext);
    const [planType, setPlanType] = useState('monthly');

    const togglePaymentType = (value) => {
        setPlanType(value);
    }

    return (
        <>
            <Row>
                <Col className="d-flex justify-content-end align-items-end">
                    <div className="buttons-container">
                        <button onClick={() => togglePaymentType('monthly')} className={`custom-button ${planType == 'monthly' ? 'active' : ''}`}>Pe an</button>
                        <button onClick={() => togglePaymentType('yearly')} className={`custom-button ${planType == 'yearly' ? 'active' : ''}`}>Pe lună</button>
                    </div>
                </Col>
            </Row>
            <div style={{}} className="packages-container">
                <CustomPackage />
                {[...Array(6)].map((_, index) => (
                    <Package key={index} />
                ))}
            </div>

        </>
    );
};

export default Packages;
