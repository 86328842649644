import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import "./style.css"

const inputButtonStyling = {
    position: 'absolute',
    right: "10px",
    top: '50%',
    transform: "translateY(-50%)",
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
}

const CustomPackage = () => {
    const { handleSubmit, control, formState: { errors } } = useForm();

    const onSubmit = (formData) => {
        console.log(formData);
    }

    return (
        <div className="p-4 shadow rounded" style={{ minWidth: '30rem', maxWidth: '30rem' }}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="pt-4 pl-4 pr-4">
                    <Col>
                        <Controller
                            name="numberOfInvoices"
                            control={control}
                            rules={{
                                required: 'Acest câmp este obligatoriu',
                                min: {
                                    value: 20,
                                    message: 'Numarul minim de facturi este 20'
                                }
                            }}
                            render={({ field }) => (
                                <Form.Group controlId="numberOfInvoices">
                                    <Form.Label style={{ fontSize: '1rem' }}>De câte facturi ai nevoie?</Form.Label>
                                    <InputGroup hasValidation style={{ position: 'relative' }}>
                                        <Form.Control
                                            type="number"
                                            name="numberOfInvoices"
                                            className={`p-3 ${errors.numberOfInvoices ? 'is-invalid' : ''}`}
                                            placeholder="Ex: 25 (minim 20)"
                                            {...field}
                                        />
                                        <Button type="submit" className="btn primary-btn" style={inputButtonStyling}>Află prețul</Button>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.numberOfInvoices && errors.numberOfInvoices.message}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            )}
                        />
                    </Col>
                </Row>
            </Form>
            <Row className="pt-4 pl-4 pr-4">
                <Col>
                    <p>Preț per factură: <span className="pl-5">4,5 lei / factură</span></p>
                </Col>
            </Row>
            <Row className="pl-4 pr-4">
                <Col>
                    <p style={{ fontWeight: '700', fontSize: '1rem' }}>Total de plată: <span className="pl-5">112 lei</span></p>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <Button className="btn primary-btn">Încarcă acum</Button>
                </Col>
            </Row>
        </div>
    );
}

export default CustomPackage;
