import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getInvoicesApi(userId) {
  const result = await getInvoices(userId);
  if (result.status === 200) {
    return result;
  }
}

export async function getFilteredInvoices(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/invoices`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let error = response.data.error
      if (!error) {
        let results = response.data.message;
        let totalResults = response.data.message.length;
        filters &&
          filters.forEach((filter) => {
            results = results.filter((invoice) => {
              return (
                (invoice.invoiceNumber && invoice.invoiceNumber.search(new RegExp(filter.value, 'i')) >= 0)
              )
            });
          });

        sortBy &&
          sortBy.forEach((sort) => {
            let index = sort.desc ? -1 : 1;
            switch (sort.id) {
              case 'invoiceNumber':
                results = results.sort((a, b) => {
                  return a.invoiceNumber < b.invoiceNumber ? -index : index
                });
                break;
              case 'user':
                results = results.sort((a, b) => {
                  return a.user.email < b.user.email ? -index : index
                });
                break;
              default:
                break;
            }
          });
        return {
          data: results.slice(from, to),
          total: results.length,
          totalResults: totalResults,
        };
      }
    })
    .catch((error) => console.error(error));
}

export async function createInvoice(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/invoices`,
    withCredentials: true,
    data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateInvoicesApi(data, id) {
  const config = {
    method: 'patch',
    url: `${API_URL}/invoices/${id}`,
    withCredentials: true,
    data: data,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteInvoicesApi(id) {
  const config = {
    method: 'delete',
    url: `${API_URL}/invoices/${id}`,
    withCredentials: true,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}


export async function getInvoices(userId) {
  const config = {
    method: 'get',
    url: `${API_URL}/invoices/${userId}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function downloadInvoice(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/invoices/download/${id}`,
    withCredentials: true,
    responseType: 'blob',
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function getInvoice(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/invoices/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}