import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { deleteInvoicesApi, downloadInvoice, getInvoicesApi } from '../../../api/invoice';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import InvoiceModal from './modals/invoice-modal';
import { toast } from 'react-toastify';
import PNotify from '../../../features/elements/p-notify';
import { formatDateTime } from '../../../utils';

function Invoices({user}) {

    // TODO add loading states
    const [invoices, setInvoices] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [activeKey, setActiveKey] = useState(null);

    useEffect(() => {
        const getInvoices = async () => {
            const result = await getInvoicesApi(user.id); // TODO Implement pagination later
            if (result && !result.data.error) {
                setInvoices(result.data.message);
            }
        }

        if (!invoices.length) {
            getInvoices();
        }
    }, [])

    const handleToggle = (key) => {
        setActiveKey(activeKey === key ? null : key);
    }

    const handleDownload = async (e) => {
        e.stopPropagation();
        const invoiceId = e.currentTarget.getAttribute('data-id');
        const result = await downloadInvoice(invoiceId);
        if (result && result.status == 200) {
            const blob = await result.data;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = invoiceId;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } else {
            console.error('Failed to download invoice');
        }
    }

    const handleDelete = (e) => {
        e.stopPropagation();
        setIsOpen(true);
        setSelectedInvoice(invoices.find(({ id }) => id == e.currentTarget.getAttribute('data-id')));
    }

    const handleDeleteConfirmation = async (result) => {
        setIsOpen(false);
        if (result) {
            const reqResult = await deleteInvoicesApi(selectedInvoice.id);
            if ((reqResult && reqResult?.status == 200) && !reqResult.data.error) {
                setInvoices(invoices.filter(({ id }) => id != selectedInvoice.id));
                return toast(
                    <PNotify
                        title='Success'
                        icon='fas fa-check'
                        text={reqResult.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-success',
                    }
                );
            } else {
                return toast(
                    <PNotify
                        title='Error'
                        icon='fas fa-times'
                        text={reqResult?.data?.message || 'A apărut o eroare!'}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-danger',
                    }
                );
            }
        }
    }

    return (
        <>
            <div className="container pt-4 pb-4 h-fit">
                <div className="bg-white shadow-lg p-4 rounded" style={{ height: '100%' }}>
                    {(!invoices || !invoices.length) && (
                        <div className="d-flex justify-content-center align-items-center mb-3 text-center flex-grow-1 h-100">
                            <h4 className="mb-0">Nu sunt facturi! <br /> Pentru a adăuga facturi, apăsați pe Generează QR</h4>
                        </div>
                    )}
                    <Accordion onSelect={handleToggle} className="custom-accordion">
                        {invoices && invoices.map((invoice, index) => (
                            <Accordion.Item key={index} eventKey={index} className='w-100'>
                                <Accordion.Header>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='mr-3'>
                                            <i className="far fa-file-pdf fa-2x"></i>
                                        </div>
                                        <div className='d-flex flex-column' style={{ lineHeight: '1rem' }}>
                                            <span className='text-left'>{invoice.invoiceNumber}</span>
                                            <span className='text-muted text-left' style={{ fontSize: '0.7rem', fontWeight: '500' }}>Factură cu cod QR</span>
                                        </div>
                                    </div>
                                    <div>
                                        {/* Nesting creates error in console. Low priority fix unless app breaks */}
                                        <Button onClick={handleDownload} data-id={invoice.id} size='xs' className='mr-2' style={{ backgroundColor: 'gray', borderColor: 'gray' }}><i className='fa fa-download'></i></Button>
                                        <Button onClick={handleDelete} data-id={invoice.id} size='xs' className='mr-3 btn-danger'><i className='fa fa-trash-alt'></i></Button>
                                        <i className={`fa fa-chevron-${activeKey === index ? 'down' : 'up'}`} />
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className='p-3'>
                                    <Row>
                                        <Col xs={12} md={2} lg={2} style={{ minWidth: '100px;' }}>
                                            {/* TODO QR CODE WILL COME HERE AFTER MICROSERVICE IMPLEMENTATION */}
                                            <img style={{ maxWidth: '100%', border: '1px solid #EBEBEB', padding: '0.6rem', borderRadius: '10px', }} width={160} src={invoice.qrImageBase64} alt="qr-code" />
                                        </Col>
                                        <Col xs={12} md={10} lg={10}>
                                            <Row>
                                                <Col x={12} md={6} lg={3} >
                                                    <Form.Label className='text-nowrap'>Tip persoana</Form.Label>
                                                    <Form.Control type="text" value={invoice.personType == 1 ? 1 : 2} readOnly />
                                                </Col>
                                                <Col x={12} md={6} lg={3} >
                                                    <Form.Label className='text-nowrap'>Număr Factură</Form.Label>
                                                    <Form.Control type="text" value={invoice.invoiceNumber} readOnly />
                                                </Col>
                                                <Col x={12} md={6} lg={3} >
                                                    <Form.Label className='text-nowrap'>Dată Factură</Form.Label>
                                                    <Form.Control type="text" value={formatDateTime(invoice.invoiceDate)} readOnly />
                                                </Col>
                                                <Col x={12} md={6} lg={3} >
                                                    <Form.Label className='text-nowrap'>Județ</Form.Label>
                                                    <Form.Control type="text" value={invoice.county} readOnly />
                                                </Col>
                                                <Col x={12} md={6} lg={3} className='mt-2'>
                                                    <Form.Label className='text-nowrap'>Comună/Oraș</Form.Label>
                                                    <Form.Control type="text" value={invoice.city} readOnly />
                                                </Col>
                                                <Col x={12} md={6} lg={3} className='mt-2'>
                                                    <Form.Label className='text-nowrap'>Sector/Sat</Form.Label>
                                                    <Form.Control type="text" value={invoice.sector} readOnly />
                                                </Col>
                                                <Col x={12} md={6} lg={3} className='mt-2'>
                                                    <Form.Label className='text-nowrap'>Nume stradă</Form.Label>
                                                    <Form.Control type="text" value={invoice.streetName} readOnly />
                                                </Col>
                                                <Col x={12} md={6} lg={3} className='mt-2'>
                                                    <Form.Label className='text-nowrap'>Număr</Form.Label>
                                                    <Form.Control type="text" value={invoice.streetNumber} readOnly />
                                                </Col>
                                                <Col x={12} md={6} lg={3} className='mt-2'>
                                                    <Form.Label className='text-nowrap'>Beneficiar</Form.Label>
                                                    <Form.Control type="text" value={invoice.beneficiaryName} readOnly />
                                                </Col>
                                                <Col x={12} md={6} lg={3} className='mt-2'>
                                                    <Form.Label className='text-nowrap'>CNP/CUI</Form.Label>
                                                    <Form.Control type="text" value={invoice.identificationNumber} readOnly />
                                                </Col>
                                                <Col x={12} md={6} lg={3} className='mt-2'>
                                                    <Form.Label className='text-nowrap'>Putere instalată</Form.Label>
                                                    <Form.Control type="text" value={invoice.installedPower} readOnly />
                                                </Col>
                                                <Col x={12} md={6} lg={3}>
                                                    <Form.Label className='text-nowrap'>Serie invertor</Form.Label>
                                                    <Form.Control type="text" value={invoice.inverterSeries} readOnly />
                                                </Col>
                                                <Col x={12} className='mt-2'>
                                                    <Form.Label className='text-nowrap'>Serii panouri</Form.Label>
                                                    <Form.Control as="textarea" value={invoice.panelSeries} readOnly />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </div>
            {selectedInvoice && (
                <InvoiceModal isOpen={isOpen} invoiceData={selectedInvoice} onClose={handleDeleteConfirmation} />
            )}
        </>
    );
}

export default Invoices;