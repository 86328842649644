import { Button, Col, Row } from "react-bootstrap";

const Package = () => {
    return (
        <div className="p-4 pb-5 pt-5 shadow rounded text-center" style={{ maxWidth: '15rem' }}>
            <Row className="pt-4 pl-4 pr-4">
                <Col>
                    <h5>5 facturi </h5>
                </Col>
            </Row>
            <Row className="pt-4 pl-4 pr-4">
                <Col>
                    <p style={{ fontSize: '2rem' }}> 50 RON </p>
                </Col>
            </Row>
            <Row className="pl-4 pr-4">
                <Col>
                    <p>Pe lună<i className="fa fa-info-circle pl-1"></i></p>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <Button className="btn primary-btn">Încarcă acum</Button>
                </Col>
            </Row>
        </div>
    )
}

export default Package;