import axios from "axios";

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function addInvoice(data) {
    const config = {
        method: 'post',
        url: `${API_URL}/invoices`,
        withCredentials: true,
        headers: {
            'Content-Type': 'multipart/form-data;'
        },
        data: data
    };

    return axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        })
}

export async function getQrPositions(id) {
    const config = {
        method: 'get',
        url: `${API_URL}/data/qrPosition/${id}`,
        withCredentials: true
    };

    return axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        })
}

export async function setQrPositions(data, id) {
    console.log(data)
    const config = {
        method: 'post',
        url: `${API_URL}/data/qrPosition/${id}`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        })
}