import Modal from "react-modal";
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import menuData from '../../utils/data/menu';
import { hasMatchingValue } from '../../utils/data/other';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { logout } from '../../api/auth';

const modalStyles = {
  overlay: {
    background: 'rgba(11, 11, 11, .8)',
    zIndex: 99999
  },
  margin: 'auto',
  content: {
    left: '50%',
    top: '50%',
    bottom: 'auto',
    right: 'auto',
    outline: 'none',
    maxWidth: '100%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
  }
};

Modal.setAppElement('#app');

export default function Sidebar({ user }) {
  let location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.querySelector('body').classList.add('loaded');
  }, [user]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  }

  const handleLogoutConfirmation = async (logoutConfirmed) => {
    if (!logoutConfirmed) {
      setIsOpen(false); // Close the modal if logout is declined
      return;
    }

    try {
      const result = await logout();
      if (result.status === 200) {
        localStorage.setItem('isLoggedIn', 'false');
        setIsOpen(false); // Close the modal on successful logout
        navigate(`${process.env.PUBLIC_URL}/login`);
      } else {
        console.error('Logout failed:', result);
        // Handle error case as needed
      }
    } catch (error) {
      console.error('Error during logout:', error);
      // Handle error case as needed
    }
  }

  return (
    <>
      <aside id='sidebar-left' className='sidebar-left'>
        <div className='nano'>
          <div className='nano-content d-flex flex-column align-items-center mt-2'>
            <img style={{ width: '13rem' }} src={process.env.PUBLIC_URL + "/assets/images/logo/qr-code-svgrepo-com-large.png"} alt="qr_logo" />
            <nav id='menu'>
              <ul className='nav flex-column w-100 align-items-start pt-3'>
                {menuData.map((item, index) => (
                  (!item.role || item.role.includes(user.level) || hasMatchingValue(user.authorization, item.role)) && (
                    <li key={`menu-item-${index}`} className='nav-item my-2'>
                      <Link to={process.env.PUBLIC_URL + item.url} className={`d-flex justify-content-center align-items-center btn ${item.url === location.pathname ? "primary-btn primary-gradient" : "primary-btn-inactive"} text-center w-100`}>
                        <i className={`${item.icon} ${item.url === location.pathname ? "mr-3 fa-3x" : "mr-2 fa-2x"} `}></i>&nbsp;{item.name}
                      </Link>
                    </li>
                  )
                ))}
                <li className='nav-item my-2'>
                  <Link onClick={handleToggle} className={`d-flex justify-content-center align-items-center btn primary-btn-inactive text-center w-100`}>
                    <i className={`fa fa-sign-out-alt mr-2 fa-2x `}></i>&nbsp;Logout
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </aside>
      {isOpen && (
        <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} keyboard={false} style={modalStyles}>
          <div className="d-flex justify-content-end" style={{ gap: '1rem' }}>
            <i className="fa fa-times fa-2x" style={{ cursor: 'pointer' }} onClick={() => handleLogoutConfirmation(false)}></i>
          </div>
          <Card>
            <Card.Body style={{ padding: '3rem' }}>
              <Row className="d-flex flex-column justify-content-center align-items-center text-center" style={{ gap: '1.5rem' }}>
                <Col>
                  <h5>Dorești să te deloghezi de pe acest cont?</h5>
                </Col>
                <Col className="d-flex flex-row justify-content-center align-items-center" style={{ gap: '4rem' }}>
                  <Button className="primary-btn pl-4 pr-4 pt-3 pb-3 text-white" onClick={() => handleLogoutConfirmation(true)} style={{ backgroundColor: '#D9D9D9' }}>Da</Button>
                  <Button className="primary-btn pl-4 pr-4 pt-3 pb-3" onClick={() => handleLogoutConfirmation(false)}>Nu</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      )}
    </>
  );
}
