import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import { withDismissible } from '../../hoc';
import { registerAccount } from '../../../api/user';
import PNotify from '../../../features/elements/p-notify';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


export default function Registration() {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        document.querySelector('body').classList.add('loaded');
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        const data = {
            email: e.target.email.value,
            password: e.target.password.value,
            passwordCheck: e.target.confirmPassword.value,
        }
        const result = await registerAccount(data);
        if (result.status === 200 && result.data.error !== 1) {
            setShow(true);
            setMessage(result.data.message);
            navigate(`${process.env.PUBLIC_URL}/login`);
        } else {
            setShow(true);
            setMessage(result?.data?.message ?? (result?.data?.error ?? 'A apărut o eroare.'));
        }
    }

    return (
        // TODO add a secondary Success view / route directly to login or log user in (easiest to just reroute to login page)
        <section className='body-sign'>
            <div className='center-sign'>
                {show && message && (
                    <Alert variant='danger'>{message}</Alert>
                )}
                <Card className='card-sign'>
                    <Card.Body className='login-card'>
                        <div className='mb-3 text-center'>
                            {/* TODO REPLACE THIS LINK */}
                             <img style={{ width: '15rem' }} src={process.env.PUBLIC_URL + "/assets/images/logo/qr-code-svgrepo-com-large.png"} alt="qr_logo" />
                        </div>
                        <h2 className='sign-title mb-4'>Înregistrează-te</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className='mb-2'>
                                <Form.Control name='email' type='email' placeholder='exemplu@gmail.com' required autoFocus />
                            </Form.Group>
                            <Form.Group className='mb-2'>
                                <Form.Control name='password' type='password' placeholder='Parolă' required autoFocus />
                            </Form.Group>
                            <Form.Group className='mb-4'>
                                <Form.Control name='confirmPassword' type='password' placeholder='Confirmă Parola' required autoFocus />
                            </Form.Group>
                            <Button type='submit' className='btn-login'>
                                Înregistrează-te
                            </Button>
                            <Button type='submit' className='btn-login btn-auth'>
                                Autentificare
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>

                <p className='text-center text-muted my-3'>
                    &copy; 2024 QR Casa Verde. All Rights Reserved.
                </p>
            </div>
        </section>
    );
}
