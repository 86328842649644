import Modal from "react-modal";
import { Row, Col, Card, Button } from 'react-bootstrap';

const modalStyles = {
    overlay: {
        background: 'rgba(11, 11, 11, .8)',
        zIndex: 99999
    },
    content: {
        left: '50%',
        top: '50%',
        bottom: 'auto',
        right: 'auto',
        outline: 'none',
        maxWidth: '100%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px',
    }
};

Modal.setAppElement('#app');

export default function InvoiceModal({ isOpen, onClose, invoiceData }) {
    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyles}>
            <div className="d-flex justify-content-end" style={{ gap: '1rem' }}>
                <i className="fa fa-times fa-2x" style={{ cursor: 'pointer' }} onClick={() => onClose(false)}></i>
            </div>
            <Card>
                <Card.Body style={{ padding: '3rem' }}>
                    <Row className="d-flex flex-column justify-content-center align-items-center text-center" style={{ gap: '1.5rem' }}>
                        <Col>
                            <h5>Esti sigur că dorești să ștergi factura {invoiceData.invoiceNumber} ?</h5>
                        </Col>
                        <Col className="d-flex flex-row justify-content-center align-items-center" style={{ gap: '4rem' }}>
                            <Button className="primary-btn pl-4 pr-4 pt-3 pb-3 text-white" onClick={() => onClose(true)} style={{ backgroundColor: '#D9D9D9' }}>Da</Button>
                            <Button className="primary-btn pl-4 pr-4 pt-3 pb-3" onClick={() => onClose(false)}>Nu</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Modal>
    )
}