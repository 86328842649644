import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import { withDismissible } from '../../hoc';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../../../api/user';
import { getUserForPasswordReset } from '../../../api';

export default function NewPassword() {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [user, setUser] = useState(null);

    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        const checkUser = async () => {
            const result = await getUserForPasswordReset(id);
            if (result && result.status === 200) {
                setUser(result.data.message);
            } else {
                setShow(true);
                setMessage('User not found!');
            }
        }

        if (!user) {
            checkUser();
        }

        document.querySelector('body').classList.add('loaded');
    }, []);

    async function handleSubmit(e) {
        // TODO handle pass and pass confirmation check in the backend (filed 1 == field 2)
        e.preventDefault();
        const data = {
            password: e.target.password.value,
            passwordCheck: e.target.passwordCheck.value,
        }
        const result = await resetPassword(user.id, data);
        if (result && result.status === 200) {
            navigate('/login');
        } else {
            setShow(true);
            setMessage('Failed to reset password!');
        }

    }

    return (
        // TODO add a secondary Success view / route directly to login or log user in (easiest to just reroute to login page)
        <section className='body-sign'>
            <div className='center-sign'>
                {show && message && (
                    <Alert variant='danger'>{message}</Alert>
                )}
                <Card className='card-sign'>
                    <Card.Body className='login-card'>
                        <div className='mb-3 text-center'>
                            {/* TODO REPLACE THIS LINK */}
                             <img style={{ width: '15rem' }} src={process.env.PUBLIC_URL + "/assets/images/logo/qr-code-svgrepo-com-large.png"} alt="qr_logo" />
                        </div>
                        <h2 className='sign-title mb-4'>Resetează-ți parola</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className='mb-2'>
                                <Form.Control name='password' type='password' placeholder='Parolă' required autoFocus />
                            </Form.Group>
                            <Form.Group className='mb-4'>
                                <Form.Control name='passwordCheck' type='password' placeholder='Confirmă Parola' required autoFocus />
                            </Form.Group>
                            <Button type='submit' className='btn-login'>
                                Confirmare
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>

                <p className='text-center text-muted my-3'>
                    &copy; 2024 QR Casa Verde. All Rights Reserved.
                </p>
            </div>
        </section>
    );
}
