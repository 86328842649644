import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { addInvoice } from '../../../api/generate-qr';
import { toast } from 'react-toastify';
import PNotify from '../../../features/elements/p-notify';
import { Controller, useForm } from 'react-hook-form'
import PDFViewer from '../../../features/elements/pdf-viewer';
import FinishInvoiceModal from '../others/finish-invoice-modal';

export default function GenerateQr({ user }) {
  const { handleSubmit, control, formState: { errors }, reset, getValues } = useForm();
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [qrPosition, setQrPosition] = useState(null);
  const [activePage, setActivePage] = useState(null);
  const [confirmedFile, setConfirmedFile] = useState(false);
  const [flowFinished, setFlowFinished] = useState({
    state: false,
    error: false
  });

  useEffect(() => {
    document.querySelector('body').classList.add('loaded');
  }, []);

  const onSubmit = async (formData) => {
    formData.userId = user.id; // Add user id to formData
    const formDataWithFile = new FormData();
    formDataWithFile.append('activePage', activePage);
    formDataWithFile.append('qrPosition', JSON.stringify(qrPosition));
    formDataWithFile.append('file', file); // Append file to FormData

    // Append other form fields to FormData
    Object.keys(formData).forEach((key) => {
      formDataWithFile.append(key, formData[key]);
    });

    const result = await addInvoice(formDataWithFile); // Call API function

    if (result && !result.data.error) {
      setFlowFinished((prev) => ({
        ...prev,
        state: true,
      }));
      return toast(
        <PNotify
          title='Success'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-success',
        }
      );
    } else {
      setFlowFinished((prev) => ({
        ...prev,
        state: true,
        error: true,
      }));
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-times'
          text={<p className='text-white'>{'A apărut o eroare!'}</p>}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
  };

  const handleFileChange = (event) => {
    setQrPosition(null);
    setActivePage(null);
    setConfirmedFile(false);
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      const fileUrl = URL.createObjectURL(uploadedFile);
      setFile(uploadedFile);
      setFileUrl(fileUrl);
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const uploadedFile = event.dataTransfer.files[0];
    if (uploadedFile) {
      const fileUrl = URL.createObjectURL(uploadedFile);
      setFile(uploadedFile);
      setFileUrl(fileUrl);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleCoordModif = (event) => {
    setQrPosition(null);
    setActivePage(null);
    setConfirmedFile(false);
  }

  const handleFileConfirmation = ({ qrPosition, activePage }) => {
    setQrPosition(qrPosition);
    setActivePage(activePage);
    setConfirmedFile(true);
  };

  const handleRetry = () => {
    setFile(null);
    setFileUrl("");
    setQrPosition(null);
  };

  const handleEndFlowChoice = (result) => {
    if (result !== 'resend') {
      reset();
      setFlowFinished({
        state: false,
        error: false
      });
      setConfirmedFile(false);
      setFileUrl("");
      setFile(null);
      setQrPosition(null);
      setActivePage(null);
    } else {
      setFlowFinished({
        state: false,
        error: false
      });
      const formData = getValues();
      onSubmit(formData);
    }
  }

  return (
    <div className="container pt-4 pb-4 h-100">
      {!file ? (
        <div className="bg-white shadow-lg p-4 rounded" style={{ height: '100%' }}>
          <div
            className="file-upload-area d-flex justify-content-center align-items-center h-100 flex-column"
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
            onClick={() => document.getElementById('fileInput').click()}
            style={{
              border: '2px dotted #ccc',
              padding: '50px',
              textAlign: 'center',
              cursor: 'pointer'
            }}
          >
            <input
              type="file"
              id="fileInput"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <i className="fas fa-cloud-upload-alt fa-10x mb-3"></i>
            <p style={{ fontSize: '1.7rem', lineHeight: '2rem' }}>Încarcă o factură finală</p>
            <p style={{ fontSize: '1rem' }}>pentru stabiliarea coordonatelor pentru atașarea QR codului</p>
          </div>
        </div>
      ) : (
        !confirmedFile ? (
          <div className="file-preview d-flex flex-grow-1 flex-column h-100 bg-white shadow-lg p-4 rounded" style={{ height: '100%' }}>
            {fileUrl && <PDFViewer fileUrl={fileUrl} handleRetry={handleRetry} handleConfirmation={handleFileConfirmation} />}
          </div>
        ) : (
          <>
            <div className="d-flex bg-white shadow-lg p-4 rounded mb-3 justify-content-between align-items-center flex-wrap">
              <div>
                Coordonate QR
              </div>
              <div className='d-flex' style={{ gap: '3rem' }}>
                <Form.Group className='mb-1 d-flex justify-content-center align-items-center'>
                  <span className='mr-2'>
                    X:
                  </span>
                  <Form.Control type='number' className='d-inline' value={parseInt(qrPosition.x)} style={{ width: '0px' }} readOnly />
                </Form.Group>
                <Form.Group className='mb-1 d-flex justify-content-center align-items-center pt-0 border-0'>
                  <span className='mr-2'>
                    Y:
                  </span>
                  <Form.Control type='number' className='d-inline' style={{ width: '0px' }} value={parseInt(qrPosition.y)} readOnly />
                </Form.Group>
              </div>
              <div>
                <Button onClick={handleCoordModif} className="primary-btn pl-3 pr-3">
                  Modifică
                </Button>
              </div>
            </div>
            <div className="bg-white shadow-lg p-4 rounded">
              <div
                className="file-upload-area d-flex justify-content-center align-items-center flex-column"
                onDrop={handleFileDrop}
                onDragOver={handleDragOver}
                onClick={() => document.getElementById('fileInput').click()}
                style={{
                  border: '2px dotted #ccc',
                  padding: '50px',
                  textAlign: 'center',
                  cursor: 'pointer'
                }}
              >
                <input
                  type="file"
                  id="fileInput"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  accept=".pdf"
                />
                <i className="fas fa-cloud-upload-alt fa-5x mb-3"></i>
                <p style={{ fontSize: '1.2rem', lineHeight: '2rem' }}>Încarcă o factură finală</p>
              </div>
              <Form className="mt-4 text-muted" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="personType" className='w-100'>
                  <Form.Label className='mr-3 mb-1'>Tip persoană</Form.Label>
                  <Controller
                    name="personType"
                    control={control}
                    rules={{ required: 'Selectați tipul de persoană' }}
                    render={({ field }) => (
                      <>
                        <Form.Check
                          inline
                          className='text-nowrap'
                          label="Persoană fizică"
                          type="radio"
                          id="inline-1"
                          value="PF"
                          {...field}
                          onChange={() => field.onChange('PF')}
                          checked={field.value === 'PF'}
                          isInvalid={!!errors.personType}
                        />
                        <Form.Check
                          inline
                          className='text-nowrap'
                          label="Persoană juridică"
                          type="radio"
                          id="inline-2"
                          value="PJ"
                          {...field}
                          onChange={() => field.onChange('PJ')}
                          checked={field.value === 'PJ'}
                          isInvalid={!!errors.personType}
                        />
                        {errors.personType && (
                          <Row>
                            <Col>
                              <Form.Control.Feedback type="invalid">
                                {errors.personType.message}
                              </Form.Control.Feedback>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  />
                </Form.Group>

                <Row className="mb-3">
                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Controller
                      name="invoiceNumber"
                      control={control}
                      rules={{ required: 'Acest câmp este obligatoriu' }}
                      render={({ field }) => (
                        <Form.Group controlId="invoiceNumber">
                          <Form.Label>Număr factură</Form.Label>
                          <Form.Control type="text" value="" {...field} isInvalid={!!errors.invoiceNumber} />
                          <Form.Control.Feedback type="invalid">
                            {errors.invoiceNumber && errors.invoiceNumber.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Controller
                      name="invoiceDate"
                      control={control}
                      rules={{ required: 'Acest câmp este obligatoriu' }}
                      render={({ field }) => (
                        <Form.Group controlId="invoiceDate">
                          <Form.Label>Dată factură</Form.Label>
                          <Form.Control type="date" value="" {...field} isInvalid={!!errors.invoiceDate} />
                          <Form.Control.Feedback type="invalid">
                            {errors.invoiceDate && errors.invoiceDate.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    />
                  </Col>

                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Controller
                      name="county"
                      control={control}
                      rules={{ required: 'Acest câmp este obligatoriu' }}
                      render={({ field }) => (
                        <Form.Group controlId="county">
                          <Form.Label>Județ</Form.Label>
                          <Form.Control type="text" value="" {...field} isInvalid={!!errors.county} />
                          <Form.Control.Feedback type="invalid">
                            {errors.county && errors.county.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: 'Acest câmp este obligatoriu' }}
                      render={({ field }) => (
                        <Form.Group controlId="city">
                          <Form.Label>Comună/Oraș</Form.Label>
                          <Form.Control type="text" value="" {...field} isInvalid={!!errors.city} />
                          <Form.Control.Feedback type="invalid">
                            {errors.city && errors.city.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Controller
                      name="sector"
                      control={control}
                      rules={{ required: 'Acest câmp este obligatoriu' }}
                      render={({ field }) => (
                        <Form.Group controlId="sector">
                          <Form.Label>Sector/Sat</Form.Label>
                          <Form.Control type="text" value="" {...field} isInvalid={!!errors.sector} />
                          <Form.Control.Feedback type="invalid">
                            {errors.sector && errors.sector.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    />
                  </Col>

                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Controller
                      name="streetName"
                      control={control}
                      rules={{ required: 'Acest câmp este obligatoriu' }}
                      render={({ field }) => (
                        <Form.Group controlId="streetName">
                          <Form.Label>Nume stradă</Form.Label>
                          <Form.Control type="text" value="" {...field} isInvalid={!!errors.streetName} />
                          <Form.Control.Feedback type="invalid">
                            {errors.streetName && errors.streetName.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Controller
                      name="streetNumber"
                      control={control}
                      rules={{ required: 'Acest câmp este obligatoriu' }}
                      render={({ field }) => (
                        <Form.Group controlId="streetNumber">
                          <Form.Label>Număr</Form.Label>
                          <Form.Control type="text" value="" {...field} isInvalid={!!errors.streetNumber} />
                          <Form.Control.Feedback type="invalid">
                            {errors.streetNumber && errors.streetNumber.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    />
                  </Col>

                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Controller
                      name="beneficiaryName"
                      control={control}
                      rules={{ required: 'Acest câmp este obligatoriu' }}
                      render={({ field }) => (
                        <Form.Group controlId="beneficiaryName">
                          <Form.Label>Nume beneficiar</Form.Label>
                          <Form.Control type="text" value="" {...field} isInvalid={!!errors.beneficiaryName} />
                          <Form.Control.Feedback type="invalid">
                            {errors.beneficiaryName && errors.beneficiaryName.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Controller
                      name="identificationNumber"
                      control={control}
                      rules={{ required: 'Acest câmp este obligatoriu' }}
                      render={({ field }) => (
                        <Form.Group controlId="identificationNumber">
                          <Form.Label>CNP/CUI</Form.Label>
                          <Form.Control type="text" value="" {...field} isInvalid={!!errors.identificationNumber} />
                          <Form.Control.Feedback type="invalid">
                            {errors.identificationNumber && errors.identificationNumber.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    />
                  </Col>

                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Controller
                      name="installedPower"
                      control={control}
                      rules={{ required: 'Acest câmp este obligatoriu' }}
                      render={({ field }) => (
                        <Form.Group controlId="installedPower">
                          <Form.Label>Putere instalată</Form.Label>
                          <Form.Control type="text" value="" {...field} isInvalid={!!errors.installedPower} />
                          <Form.Control.Feedback type="invalid">
                            {errors.installedPower && errors.installedPower.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <Controller
                      name="inverterSeries"
                      control={control}
                      rules={{ required: 'Acest câmp este obligatoriu' }}
                      render={({ field }) => (
                        <Form.Group controlId="inverterSeries">
                          <Form.Label>Serie invertor</Form.Label>
                          <Form.Control type="text" value="" {...field} isInvalid={!!errors.inverterSeries} />
                          <Form.Control.Feedback type="invalid">
                            {errors.inverterSeries && errors.inverterSeries.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    />
                  </Col>

                  <Col xs={12}>
                    <Controller
                      name="panelSeries"
                      control={control}
                      rules={{ required: 'Acest câmp este obligatoriu' }}
                      render={({ field }) => (
                        <Form.Group controlId="panelSeries">
                          <Form.Label>Serii panouri</Form.Label>
                          <Form.Control as="textarea" value="" rows={4} {...field} isInvalid={!!errors.panelSeries} />
                          <Form.Control.Feedback type="invalid">
                            {errors.panelSeries && errors.panelSeries.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={12} className="d-flex justify-content-center align-items-center">
                    <Button type='submit' className="primary-btn pl-3 pr-3">
                      <span style={{ fontSize: '1rem', fontWeight: '500' }}>Generează QR</span>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
            {flowFinished.state && (
              <FinishInvoiceModal isOpen={flowFinished.state} isError={flowFinished.error} onClose={handleEndFlowChoice} />
            )}

          </>
        )
      )}
    </div>
  );
}
